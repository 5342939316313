import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function CorporateWebsitePage(props) {
    return (
        <div className="container draft-view">
           <h1>Corporate Website Development</h1>
           <p className="text-muted">Corporate website development is a specific niche of web development. The main goal of a corporate website is to achieve business goals with increasing sales, providing a high-quality company presentation on the Internet, increasing customer loyalty, and competitive advantages in your industry and location.</p>
           <p className="text-muted">Wescale offers its clients a full range of corporate website design and development.</p>
           <p className="text-muted"></p>
           <h2>What is a&nbsp;Corporate Website?</h2>
           <p className="text-muted">A corporate website represents the company it was built for. It conveys the company’s image and concept to the world and therefore, must be convenient for all segments of the audience: clients, shareholders, employees, investors, suppliers, and local communities. That is why developing great corporate websites requires extensive experience and skills.</p>
           <p className="text-muted">In short words, the corporate website is like a company’s personal assistant or business development executive that a company owner hires to work 24/7 without days off.</p>
           <p className="text-muted">Design, functionality, and content should all be thought out in very great detail.</p>
           <p className="text-muted"></p>
           <h5>Several basic principles should be observed when constructing a corporate website:</h5>
           <ul>
              <li className="text-muted">Creating a memorable design using elements of the corporate Identity</li>
              <li className="text-muted">Focusing on the user needs</li>
              <li className="text-muted">Becoming personable</li>
              <li className="text-muted">Emphasizing the usability</li>
              <li className="text-muted">Creating high-quality unique content</li>
              <li className="text-muted">Functionality</li>
              <li className="text-muted">Reliability, Stability, and Adaptability</li>
           </ul>
           <p className="text-muted"><br /></p>
           <h2 >What result will you get?</h2>
           <p className="text-muted"><br/></p>
           <h5>Quality assurance</h5>
           <p className="text-muted">This includes testing and optimizing HTML, JS, and CSS, simple SEO optimization on the page, cross-browser compatibility testing, and creating a responsive version of the site to drive mobile traffic.</p>
           <p className="text-muted"><br /></p>
           <h5>High website download speed</h5>
           <p className="text-muted">The minimum download speed for a corporate website using Google Test Speed on computers and mobile devices, regardless of the speed of the Internet connection.</p>
           <p className="text-muted"><br /></p>
           <h5>A reliable partner and project support from the agency</h5>
           <p className="text-muted">You can be sure with certainty that after the delivery of the project, we will provide all possible support. Whether it is the development of new functions for the site, filling the resource, writing texts, or other tasks. You can always be sure of us!</p>
           <p className="text-muted"><br /></p>
           <h5>An effective platform for business</h5>
           <p className="text-muted">Your corporate website is a marketing resource, which is 100% achieving its goals. Regardless of the thematic direction of the business of the customer and complex from a technical point of view, the implementation of the project, we develop sites that leave no one<br /></p>
        </div>
    );
}
